@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');

body {
  margin: 0;
  font-family: 'Inconsolata', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: black;
  color: #ffffff;
}

* {
  font-family: 'Inconsolata', monospace;
  background-color: black;
  color: #ffffff;
}

code {
    font-family: 'Inconsolata', monospace;
}
