.home-page {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-page img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.6;
}

.text-overlay {
  position: relative;
  color: white;
  z-index: 1;
  text-align: center;
}

.text-overlay h1 {
  padding-left: 20px;
  
}
